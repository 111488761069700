import {getKey, removeKey, setKey} from './IonicStorage'

interface UserStorageParams {
    email: string | null
    token: string | null
    tenantShortName: string | null
}

const getUserStorage = async () => {
    return {
        token: await getTokenFromStorage(),
        email: await getEmail(),
        tenant: await getTenantFromStorage()
    }
}

const saveUserStorage = (user: UserStorageParams) => {
    saveTokenToStorage(user.token)
    saveEmail(user.email)
    saveTenantToStorage(user.tenantShortName)
}

const deleteUserStorage = () => {
    deleteTokenFromStorage()
    deleteEmail()
    deleteTenantFromStorage()
}

const getEmail = async () => {
    return await getKey('email')
}

const saveEmail = (email: string | null) => {
    setKey('email', email)
}

const deleteEmail = () => {
    removeKey('email')
}

const getTokenFromStorage = async () => {
    return await getKey('auth_token')
}

const saveTokenToStorage = (token: string | null) => {
    setKey('auth_token', token)
}

const deleteTokenFromStorage = () => {
    removeKey('auth_token')
}

const getTenantFromStorage = async () => {
    return await getKey('tenant_name')
}

const saveTenantToStorage = (tenantName: string | null) => {
    setKey('tenant_name', tenantName)
}

const deleteTenantFromStorage = () => {
    removeKey('tenant_name')
}

export {
    getTokenFromStorage,
    saveTokenToStorage,
    deleteTokenFromStorage,
    saveUserStorage,
    getUserStorage,
    deleteUserStorage,
    deleteEmail,
    saveEmail,
    getEmail,
    getTenantFromStorage,
    saveTenantToStorage,
    deleteTenantFromStorage
}

export type {UserStorageParams}
