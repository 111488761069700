import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../store'
import {deleteProjectStorage, saveProjectStorage} from '../../storage/ProjectStorage'

interface Project {
    id: number | null
    name: string | null
    subcategoryType: 'INUNIT' | 'COMMON' | null
}

const initialState = {
    id: null,
    name: null,
    subcategoryType: null
} as Project

const projectSlice = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        changeProject: (state, {payload}) => {
            saveProjectStorage(payload)
            return payload
        },
        resetProject: () => {
            deleteProjectStorage()
            return initialState
        }
    },
})

export const selectProject = (state: RootState) => state.project

export const {resetProject, changeProject} = projectSlice.actions

export default projectSlice.reducer
