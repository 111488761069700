import {Drivers, Storage} from "@ionic/storage"

let storage: Storage

export const createStore = (name = "__my_storage") => {
    storage = new Storage({
        name,
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })
    storage.create()
}


export const setKey = (key: string, val: any) => {
    storage.set(key, val)
}

export const getKey = async (key: string) => {
    const val = await storage.get(key) || undefined
    return val
}

export const removeKey = (key: string) => {
    storage.remove(key)
}

export const clear = () => {
    storage.clear()
}

// export const setObject = async (key, id, val) => {
//
//     const all = await storage.get(key)
//     const objIndex = await all.findIndex(a => parseInt(a.id) === parseInt(id))
//
//     all[objIndex] = val
//     set(key, all)
// }
//
// export const removeObject = async (key, id) => {
//
//     const all = await storage.get(key)
//     const objIndex = await all.findIndex(a => parseInt(a.id) === parseInt(id))
//
//     all.splice(objIndex, 1)
//     set(key, all)
// }
//
// export const getObject = async (key, id) => {
//
//     const all = await storage.get(key)
//     const obj = await all.filter(a => parseInt(a.id) === parseInt(id))[0]
//     return obj
// }
