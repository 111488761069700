import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../config/AxiosConfig'
import {alertMessage, toastMessage} from '../../helper/Utils'
import {RootState} from '../store'
import {deleteUserStorage, getUserStorage, saveUserStorage} from '../../storage/AuthStorage'

interface LoginUserValue {
    email: string
    password: string
    type?: string
}

interface UserState {
    email: string | null
    firstname: string | null
    lastname: string | null
    token: string | null
    phoneNumber: string | null
    profileImageName: string | null
    tenantName: string | null
    roleName: string | null
    permissions: any[] | null
}

const initialState = {
    email: null,
    firstname: null,
    lastname: null,
    token: null,
    phoneNumber: null,
    profileImageName: null,
    tenantName: null,
    roleName: null,
    permissions: null
} as UserState

export const LoginUser = createAsyncThunk(
    'user/login',
    async (value: LoginUserValue) => {
        const {data} = await axios.post('/identity/login', {
            email: value.email,
            password: value.password,
        })
        return {...data, email: value.email, type: value.type}
    }
)

export const initUserFromStorage = createAsyncThunk(
    'user/initUser',
    async () => {
        const userStorage = await getUserStorage()
        const {data} = await axios.get('/user/info/me')
        return {...data, token: userStorage.token, tenant: userStorage.tenant}
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        resetUser: () => {
            deleteUserStorage()
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(LoginUser.fulfilled, (state, {payload}) => {
            const {email, token, type, tenantShortName} = payload
            state.email = email
            state.token = token
            state.tenantName = tenantShortName
            saveUserStorage(payload)
            type ? toastMessage(`${type} Successfully`, 'success') : toastMessage('Login Successfully', 'success')
        })
        builder.addCase(LoginUser.rejected, (state, payload) => {
            alertMessage('Login Failed !', 'Please enter your email and password again.')
        })
        builder.addCase(initUserFromStorage.fulfilled, (state, {payload}) => {
            state.email = payload.email
            state.firstname = payload.firstName
            state.lastname = payload.lastName
            state.phoneNumber = payload.phoneNumber
            state.profileImageName = payload.profileImageName
            state.roleName = payload.role.name
            state.permissions = payload.role.permission.map((value: any) => value.name)
            state.token = payload.token
            state.tenantName = payload.tenant
        })
        builder.addCase(initUserFromStorage.rejected, (state, action) => {
            deleteUserStorage()
            window.location.href = '/'
            return initialState
        })
    },
})

export const selectUser = (state: RootState) => state.user

export const {resetUser} = userSlice.actions

export default userSlice.reducer
