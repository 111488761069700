import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import Store from './redux/store'
import {defineCustomElements} from '@ionic/pwa-elements/loader'
import 'moment/locale/th'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Variables Project */
import './assets/css/theme.css'
import './assets/css/style.css'

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ))

ReactDOM.render(
    <Provider store={Store}>
        <Suspense fallback={<div className="loading"/>}>
            <App/>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)

defineCustomElements(window)
