import {
    API_DOCUMENT_SET,
    API_ITEM_TYPE_OPTION,
    API_ORDER_BY_OPTION,
    API_PROJECT_TYPE,
    API_SORT_BY_OPTION,
    API_STATUS_OPTION,
    API_SUBCATEGORY_STATUS,
    API_SUBCATEGORY_TYPE
} from './ObjectFreeze'

export const statusSelectOption = [
    {
        value: API_STATUS_OPTION.ALL, // CHECK SEND NULL IN PAGE
        label: 'ALL'
    },
    {
        value: API_STATUS_OPTION.OPEN,
        label: 'OPEN'
    },
    {
        value: API_STATUS_OPTION.IN_PROGRESS,
        label: 'IN PROGRESS'
    },
    {
        value: API_STATUS_OPTION.PASS,
        label: 'PASS'
    },
    {
        value: API_STATUS_OPTION.NOT_PASS,
        label: 'NOT PASS'
    },
    {
        value: API_STATUS_OPTION.CORRECTED,
        label: 'CORRECTED'
    },
]

export const documentSetOption = [
    {
        value: API_DOCUMENT_SET.LEGAL,
        label: 'เอกสารกฎหมาย'
    },
    {
        value: API_DOCUMENT_SET.BLUEPRINT,
        label: 'เอกสารแบบก่อสร้าง'
    },
    {
        value: API_DOCUMENT_SET.MATERIAL_SPEC,
        label: 'เอกสารสเปควัสดุ'
    },
    {
        value: API_DOCUMENT_SET.OTHER,
        label: 'เอกสารอื่นๆ'
    }
]

export const documentTypeOption = [
    {
        value: 'HardCopy',
        label: 'Hard Copy'
    },
    {
        value: 'SoftCopy',
        label: 'Soft Copy'
    }
]

export const collectionSelectOption = [
    {
        value: API_ITEM_TYPE_OPTION.ARCHITECTURE,
        label: 'ARCHITECTURE'
    },
    {
        value: API_ITEM_TYPE_OPTION.ASSET,
        label: 'ASSET'
    },
    {
        value: API_ITEM_TYPE_OPTION.LANDSCAPE,
        label: 'LANDSCAPE'
    },
    {
        value: API_ITEM_TYPE_OPTION.SYSTEM,
        label: 'SYSTEM'
    },
]

export const orderByCategory = [
    {
        value: API_ORDER_BY_OPTION.DEFECT_COUNT,
        label: 'Defect Count'
    },
    {
        value: API_ORDER_BY_OPTION.DEFECT_PROGRESS,
        label: 'Defect Progress'
    },
    {
        value: API_ORDER_BY_OPTION.CATEGORY_CODE,
        label: 'Category Code'
    },
]

export const orderByCompany = [
    {
        value: API_ORDER_BY_OPTION.DEFECT_COUNT,
        label: 'Defect Count'
    },
    {
        value: API_ORDER_BY_OPTION.DEFECT_PROGRESS,
        label: 'Defect Progress'
    },
    {
        value: API_ORDER_BY_OPTION.COMPANY_TITLE,
        label: 'Company Title'
    },
]

export const sortBy = [
    {
        value: API_SORT_BY_OPTION.ASCEND,
        label: 'A-Z'
    },
    {
        value: API_SORT_BY_OPTION.DESCEND,
        label: 'Z-A'
    }
]

export const subcategoryType = [
    {
        value: API_SUBCATEGORY_TYPE.COMMON,
        label: 'COMMON'
    },
    {
        value: API_SUBCATEGORY_TYPE.INUNIT,
        label: 'IN-UNIT'
    },
]

export const projectSubcategoryType = [
    {
        value: API_SUBCATEGORY_TYPE.ALL, // CHECK SEND NULL IN PAGE
        label: 'ALL'
    },
    {
        value: API_SUBCATEGORY_TYPE.INUNIT,
        label: 'IN-UNIT'
    },
    {
        value: API_SUBCATEGORY_TYPE.COMMON,
        label: 'COMMON'
    }
]

export const projectType = [
    {
        value: API_PROJECT_TYPE.HOUSING,
        label: API_PROJECT_TYPE.HOUSING
    },
    {
        value: API_PROJECT_TYPE.CONDOMINIUM,
        label: API_PROJECT_TYPE.CONDOMINIUM
    }
]

export const subcategoryStatus = [
    {
        value: API_SUBCATEGORY_STATUS.NONE,
        label: 'NONE'
    },
    {
        value: API_SUBCATEGORY_STATUS.IN_PROGRESS,
        label: 'IN PROGRESS'
    },
    {
        value: API_SUBCATEGORY_STATUS.SOLD,
        label: 'SOLD'
    },
    {
        value: API_SUBCATEGORY_STATUS.COMPLETE,
        label: 'COMPLETE'
    },
]

export const multiProgressSubcategoryStatus = [
    {
        value: API_SUBCATEGORY_STATUS.SOLD,
        name: 'SOLD',
        color: '#4472c4'
    },
    {
        value: API_SUBCATEGORY_STATUS.INPROGRESS,
        name: 'IN PROGRESS',
        color: '#ed7d31'
    },
    {
        value: API_SUBCATEGORY_STATUS.COMPLETED,
        name: 'COMPLETED',
        color: '#a5a5a5'
    },
    {
        value: API_SUBCATEGORY_STATUS.APPROVED,
        name: 'APPROVED',
        color: '#ffc000'
    },
    {
        value: API_SUBCATEGORY_STATUS.NONE,
        name: 'NONE',
        color: '#5b9bd5'
    }
]

export const sortOrder = [
    {
        value: 'ASCEND',
        label: 'ASCEND'
    },
    {
        value: 'DESCEND',
        label: 'DESCEND'
    },
]