import axios from 'axios'
import {alertMessage} from '../helper/Utils'
import {getTokenFromStorage} from '../storage/AuthStorage'
import history from '../helper/history'
import Store from '../redux/store'
import {resetUser} from '../redux/slice/userSlice'
import {resetProject} from '../redux/slice/projectSlice'
import {getProjectStorage} from '../storage/ProjectStorage'

const listPathNotSendProjectId = [
    '/project',
    '/role',
]

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(
    async config => {
        let auth = await getTokenFromStorage()
        let project = await getProjectStorage()
        if (auth) {
            config.headers = {
                ...config.headers,
                'Authorization': auth
            }
            const isSendProjectId = !listPathNotSendProjectId.some((value) => value === config.url)
            if (project && config.method !== 'get' && isSendProjectId) {
                config.headers['projectId'] = project.id
                config.headers['projectTitle'] = project.name
                config.params = {...config.params, projectId: project.id}
            }
        }
        return config
    },
    error => {
        if (process.env.NODE_ENV === 'development') console.error('**intercepter request**', error)
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            if (error.response.status !== 404 && error.response.status !== 401 && error.response.status !== 403) {
                alertMessage(
                    error.response.data.error || error.response.data,
                    error.response.data.message
                )
            } else if (error.response.status === 403) {
                alertMessage(
                    'Permission denied',
                    'You aren\'t authorized to use this page or function.'
                )
            } else if (error.response.status === 401) {
                Store.dispatch(resetUser())
                Store.dispatch(resetProject())
                alertMessage(
                    'Session Expire',
                    'Your session has been expired, please login again.'
                )
                history.replace('/auth/login')
            }
        } else if (!error.response) {
            alertMessage('NETWORK_ERROR', 'Something went wrong. Please try again later.')
        }
        if (process.env.NODE_ENV === 'development') console.error('**interceptors error**', error.response)
        return Promise.reject(error)
    }
)

export default axios
