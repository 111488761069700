import {getKey, removeKey, setKey} from './IonicStorage'

interface ProjectSelected {
    id: number | null
    name: string | null,
    subcategoryType: 'INUNIT' | 'COMMON' | null
}

const saveProjectStorage = (project: ProjectSelected) => {
    setKey('project', {
        id: project.id,
        name: project.name,
        subcategoryType: project.subcategoryType
    })
}

const getProjectStorage = async () => {
    return await getKey('project')
}

const deleteProjectStorage = () => {
    removeKey('project')
}

export {getProjectStorage, saveProjectStorage, deleteProjectStorage}

