import {combineReducers} from '@reduxjs/toolkit'
import userSlice from './slice/userSlice'
import projectSlice from "./slice/projectSlice";
import defectSlice from './slice/defectSlice';


const rootReducer = combineReducers({
    user: userSlice,
    project: projectSlice,
    defect: defectSlice
})

export default rootReducer
