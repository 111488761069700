export const API_PERMISSION = Object.freeze({
    EDIT_USER: 'editUser',
    DELETE_USER: 'deleteUser',
    CHANGE_PASSWORD_BY_USER_ID: 'changePasswordUserId',
    ADD_PROJECT: 'addProject',
    EDIT_PROJECT: 'editProject',
    DELETE_PROJECT: 'deleteProject',
    GET_DEFECT: 'getDefect',
    ADD_DEFECT: 'addDefect',
    EDIT_DEFECT: 'editDefect',
    ADD_COLLECTION: 'addCollection',
    DELETE_DEFECT: 'deleteDefect',
    ADD_INSPECTION: 'addInspection',
    EDIT_INSPECTION: 'editInspection',
    DELETE_INSPECTION: 'deleteInspection',
    ADD_CATEGORY: 'addCategory',
    EDIT_CATEGORY: 'editCategory',
    DELETE_CATEGORY: 'deleteCategory',
    ADD_SUBCATEGORY: 'addSubcategory',
    EDIT_SUBCATEGORY: 'editSubcategory',
    DELETE_SUBCATEGORY: 'deleteSubcategory',
    GET_TOPIC: 'getTopic',
    ADD_TOPIC: 'addTopic',
    EDIT_TOPIC: 'editTopic',
    DELETE_TOPIC: 'deleteTopic',
    ACTIVITY_FEED: 'activityFeed',
    GET_COMPANY: 'getCompany',
    ADD_COMPANY: 'addCompany',
    EDIT_COMPANY: 'editCompany',
    DELETE_COMPANY: 'deleteCompany',
    ADD_COMPANY_TO_PROJECT: 'addToProject',
    DELETE_COMPANY_FORM_PROJECT: 'deleteCompanyFromProject',
    APPROVE_DEFECT: 'approveDefect',
    ENABLE_USER: 'enableUser',
    SET_ROLE: 'setRole',
    APPROVE_CATEGORY: 'approveCategory',
    GET_PROJECT_ALL: 'getProjectAll',
    GET_USER_BY_ID: 'getUserId',
    GET_ROLE: 'getRole',
    ADD_ROLE: 'postRole',
    EDIT_ROLE: 'putRole',
    DELETE_ROLE: 'deleteRole',
    ADD_COMPONENT: 'postComponent',
    EDIT_COMPONENT: 'putComponent',
    DELETE_COMPONENT: 'deleteComponent'
})

export const API_PERMISSION_NOT_USE = Object.freeze({
    GET_USER: 'getUser',
    GET_PROJECT: 'getProject',
    GET_DEFECT: 'getDefect',
    GET_INSPECTION: 'getInspection',
    GET_CATEGORY: 'getCategory',
    GET_SUBCATEGORY: 'getSubcategory',
    EDIT_USER_ME: 'editUserMe',
    CHANGE_PASSWORD_ME: 'changePasswordMe',
    GET_COMPONENT: 'getComponent',
})
//  ----- Lasted Update 28/3/2022 (API_PERMISSION && API_PERMISSION_NOT_USE)

export const API_STATUS_OPTION = Object.freeze({
    ALL: 'ALL',
    OPEN: 'OPEN',
    IN_PROGRESS: 'INPROGRESS',
    CORRECTED: 'CORRECTED',
    PASS: 'PASS',
    NOT_PASS: 'NOTPASS'
})

export const API_ITEM_TYPE_OPTION = Object.freeze({
    SYSTEM: 'SYSTEM',
    ASSET: 'ASSET',
    LANDSCAPE: 'LANDSCAPE',
    ARCHITECTURE: 'ARCHITECTURE'
})

export const API_DOCUMENT_SET = Object.freeze({
    LEGAL: 'Legal',
    MATERIAL_SPEC: 'MaterialSpec',
    BLUEPRINT: 'Blueprint',
    OTHER: 'Other'
})

export const API_ORDER_BY_OPTION = Object.freeze({
    DEFECT_PROGRESS: 'DEFECT_PROGRESS',
    DEFECT_COUNT: 'DEFECT_COUNT',
    CATEGORY_CODE: 'CATEGORY_CODE',
    COMPANY_TITLE: 'COMPANY_TITLE'
})

export const API_SORT_BY_OPTION = Object.freeze({
    ASCEND: 'ASCEND',
    DESCEND: 'DESCEND'
})

export const API_SUBCATEGORY_TYPE = Object.freeze({
    ALL: 'ALL',
    INUNIT: 'INUNIT',
    COMMON: 'COMMON',
})

export const API_PROJECT_TYPE = Object.freeze({
    HOUSING: 'HOUSING',
    CONDOMINIUM: 'CONDOMINIUM',
})

export const API_SUBCATEGORY_STATUS = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    NONE: 'NONE',
    SOLD: 'SOLD',
    COMPLETE: 'COMPLETE',
    APPROVED: 'APPROVED',
    INPROGRESS: 'INPROGRESS',
    COMPLETED: 'COMPLETED'
})
