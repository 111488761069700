import React from 'react'
import {createGesture, Gesture, IonIcon, IonLabel, isPlatform} from '@ionic/react'
import {documentTypeOption, statusSelectOption} from '../constants/SelectOption'
import moment from 'moment'
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera'
import axios from 'axios'
import {Browser} from '@capacitor/browser'
import {File} from '@awesome-cordova-plugins/file'
import {useLocation} from 'react-router'
import {ReactComponent as Pdf} from '../assets/svg/file-pdf-regular.svg'
import {ReactComponent as Word} from '../assets/svg/file-word-regular.svg'
import {ReactComponent as Excel} from '../assets/svg/file-excel-regular.svg'
import {addCircle, informationCircle, pencil, removeCircle} from 'ionicons/icons'
import {API_PERMISSION_NOT_USE} from '../constants/ObjectFreeze'

export const alertMessage = (title: string, message: string, textOk: string = 'Close') => {
    const alert = document.createElement('ion-alert')
    alert.header = title
    alert.message = message
    alert.buttons = [textOk]
    document.body.appendChild(alert)
    return alert.present()
}

export const toastMessage = (text: string, color?: string) => {
    const toast = document.createElement('ion-toast')
    toast.message = text
    toast.duration = 3000
    toast.buttons = [
        {
            text: 'Close',
            role: 'cancel'
        }
    ]
    toast.color = color
    document.body.appendChild(toast)
    return toast.present()
}

export const showErrorFormMsg = (touched: any, errors: any) => {
    if (touched && errors) {
        return <IonLabel color="danger" className="pl-3" style={{fontSize: 12}}>{errors}</IonLabel>
    }
}

export const showDateErrorMsg = (errors: any) => {
    return errors && <IonLabel color="danger" className="pl-3" style={{fontSize: 12}}>{errors}</IonLabel>
}

export const mapTextStatus = (statusAPI: string) => {
    const result = statusSelectOption.find((option) => option.value === statusAPI)
    return result && result.label
}

export const mapDocumentType = (type: string) => {
    const result = documentTypeOption.find((option) => option.value === type)
    return result && result.label
}

export const takePicture = async (setImgSource: Function, setImgName?: (name: string) => void, stringName?: string) => {
    try {
        const image = await Camera.getPhoto({
            quality: 60,
            width:1024,
            height:1024,
            source: CameraSource.Camera,
            resultType: CameraResultType.Uri,
            allowEditing: true
        })
        setImgName && setImgName(`${stringName}.${image.format}`)
        if (isPlatform('ios')) {
            let arrayPath = image.path?.split('/')
            if (arrayPath) {
                let fileName = arrayPath.pop()
                const filePath = arrayPath.join('/')
                if (fileName) setImgSource(File.readAsDataURL(filePath, fileName))
            }
        } else {
            setImgSource(image.webPath)
        }
    } catch (e: any) {
        if (process.env.NODE_ENV === 'development') console.warn(e.message)
    }
}

export const selectPicture = async (setImgSource: Function, setImgName?: (name: string) => void, stringName?: string) => {
    try {
        const image = await Camera.pickImages({
            limit: 1,
            quality: 60,
            width:1024,
            height:1024,
        })
        setImgName && setImgName(`${stringName}.${image.photos[0].format}`)
        if (isPlatform('ios')) {
            let arrayPath = image.photos[0].path?.split('/')
            if (arrayPath) {
                let fileName = arrayPath.pop()
                const filePath = arrayPath.join('/')
                if (fileName) {
                    let base64 = await File.readAsDataURL(filePath, fileName)
                    setImgSource(base64)
                }
            }
        } else {
            setImgSource(image.photos[0].webPath)
        }
    } catch (e: any) {
        if (process.env.NODE_ENV === 'development') console.warn(e.message)
    }
}

export const getMomentTime = (value: string[], format: string) => {
    if (value.length === 3) {
        return moment(`${value[1]}/${value[2]}/${value[0]}`).format(format)
    } else if (value.length === 5) {
        return moment(`${value[1]}/${value[2]}/${value[0]} ${value[3]}:${value[4]}`).format(format)
    } else if (value.length === 6 || value.length === 7) {
        return moment(`${value[1]}/${value[2]}/${value[0]} ${value[3]}:${value[4]}:${value[5]}`).format(format)
    } else {
        return ''
    }
}

export const textCapitalize = (text: string) => {
    const arr = text.toLowerCase().split(' ')
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr.join(' ')
}

export const handleClickItemFile = async (
    fileName: string,
    setFileName: (filename: string) => void,
    setIsOpen: (isOpen: boolean) => void
) => {
    try {
        const fileNameSplit = fileName.split('.')
        const dataType = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : ''
        setFileName(fileName)
        if (dataType.match(/(pdf|doc|xls)/)) {
            const {data} = await axios.get(`/image/url?fileName=${fileName}`)
            await Browser.open({url: data})
        } else {
            setIsOpen(true)
        }
    } catch (e) {
        toastMessage('File Not found ', 'medium')
    }
}

export const secondTime = (count: number) => {
    return moment().minute(0).second(count).format('m:ss')
}

export const base64toBlob = (dataURI: string, type: string) => {
    let byteString = atob(dataURI.split(',')[1])
    let ab = new ArrayBuffer(byteString.length)
    let ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], {type: type})
}

export const thumbnailControl = async (
    setVisible: (visible: boolean) => void,
    elementId: string,
    thumbnail: string,
    setThumbnail: (thumbnail: string) => void
) => {
    if (thumbnail) {
        const item = document.querySelector(elementId)
        let active: boolean = false

        const longPressCheck = () => {
            setTimeout(() => {
                if (active) {
                    setThumbnail(thumbnail)
                    setVisible(true)
                }
            }, 1000)
        }

        if (item) {
            const gesture: Gesture = await createGesture({
                el: item,
                gestureName: 'long-press',
                threshold: 0,
                onStart: () => {
                    active = true
                    longPressCheck()
                },
                onEnd: () => {
                    active = false
                    setVisible(false)
                }
            })
            gesture.enable(true)
        }
    }
}

export const renderColorOption = (isShow: boolean, isEdit?: boolean, isRemove?: boolean) => {
    if (isShow) {
        if (isEdit && isRemove) {
            return (
                <div className="two-option-item-color">
                    <div className="option--item-edit-style"/>
                    <div className="option--item-delete-style"/>
                </div>
            )
        } else if (isEdit) {
            return <div className="option--item-edit-style"/>
        } else if (isRemove) {
            return <div className="option--item-delete-style"/>
        }
    }
}

export const changeExtension = (extension: string) => {
    let result = ''
    if (extension.match('msword')) {
        result = 'doc'
    } else if (extension.match('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        result = 'docx'
    } else if (extension.match('vnd.ms-excel')) {
        result = 'xls'
    } else if (extension.match('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        result = 'xlsx'
    } else {
        result = extension.split('/')[1]
    }
    return result
}

export const getIcon = (dataType: string, propsSvg: any) => {
    let icon = null
    if (dataType.match('pdf')) {
        icon = <Pdf {...propsSvg} />
    } else if (dataType.match('doc')) {
        icon = <Word {...propsSvg} />
    } else if (dataType.match('xls')) {
        icon = <Excel {...propsSvg} />
    }
    return icon
}

export const useQuery = () => {
    const {search} = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const renderIcon = (requestMethod: string) => {
    let icon = informationCircle
    let color = 'medium'
    if (requestMethod === 'POST') {
        icon = addCircle
        color = 'success'
    } else if (requestMethod === 'PUT') {
        icon = pencil
        color = 'warning'
    } else if (requestMethod === 'DELETE') {
        icon = removeCircle
        color = 'danger'
    }
    return <IonIcon slot="start" style={{fontSize: 36, marginRight: 15}} icon={icon} color={color}/>
}

export const mapLabelSelectOption = (keyValue: string, arrayOption: { value: string, label: string }[]) => {
    const result = arrayOption.find(({value}) => keyValue === value)
    return result ? result.label : ''
}

export const checkPermission = (myPermissions: any[] | null, permissionName: string | string[], isCheckAllPermission?: boolean) => {
    if (myPermissions) {
        if (typeof permissionName === 'string') {
            return myPermissions.some((value: any) => value === permissionName)
        } else {
            let result = false
            let countPermissionFound = 0
            const numberOfPermissionName = isCheckAllPermission ? permissionName.length : 1
            permissionName.forEach(name => {
                if (myPermissions.some((value: any) => value === name)) {
                    countPermissionFound++
                    if (countPermissionFound === numberOfPermissionName) {
                        result = true
                        return
                    }
                }
            })
            return result
        }
    } else {
        return false
    }
}

export const getListPermissionUseOnly = (apiPermission: any[]) => {
    return apiPermission.filter((value: any) => {
        let result = true
        for (const permissionNotUser of Object.entries(API_PERMISSION_NOT_USE)) {
            if (value.name === permissionNotUser[1]) {
                result = false
                return
            }
        }
        return result
    })
}

export const getListPermissionUseGetById = (permission: any[]) => {
    return permission.filter((val: any) => Object.entries(API_PERMISSION_NOT_USE).some(value => value[1] === val.name))
        .map((val: any) => val.id.toString())
}

export const getDistrictArray = (main: any[], secondary: any[]) => {
    return Array.from(new Set(main.concat(secondary)))
}

export const isDeveloper = (role: string | null) => {
    return role === 'ROLE_DEVELOPER'
}