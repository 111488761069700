import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../store'

interface defect {
    inspectionId?: number
    categoryId?: number
    subCategoryId?: number
    companyId?: number
    topic?: number
    componentId? : number
}

const initialState: defect = {
    inspectionId: undefined,
    categoryId: undefined,
    subCategoryId: undefined,
    companyId: undefined,
    topic: undefined,
    componentId: undefined
}

const defectSlice = createSlice({
    name: 'defect',
    initialState: initialState,
    reducers: {
        changeDefect: (state, {payload}) => {
            return payload
        },
        resetDefect: () => {
            return initialState
        }
    },
})

export const selectDefect = (state: RootState) => state.defect

export const {changeDefect, resetDefect} = defectSlice.actions

export default defectSlice.reducer
